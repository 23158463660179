@import "./../../Variables.scss";

.profile-form {
  .form-control:disabled {
    background-color: map-get($theme-colors, "white");
  }
  .form-group {
    .badge {
      display: table-caption;
      font-size: 18px;
      border-radius: 20px;
      padding: 5px 25px;
    }
  }
}

.email-support {
  a {
    color: map-get($theme-colors, "black");
  }
}
