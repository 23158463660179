@import "./../../Variables.scss";

.data-table {
  text-align: center;

  thead {
    background-color: map-get($theme-colors, "background");

    tr {
      th {
        border: 0;
        color: rgba(map-get($theme-colors, "black"), 0.5);
        font-size: 18px;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }

  tbody {
    tr {
      &:first-child {
        td {
          border-top: 0;
        }
      }

      td {
        color: map-get($theme-colors, "darkborder");

        &:first-child {
          font-weight: 700;
          color: map-get($theme-colors, "primary");
        }

        &.close-date {
          color: map-get($theme-colors, "primary");
          font-weight: 700;
        }
        .btn-invoice {
          background-color: map-get($theme-colors, "siwego-green");
          color: map-get($theme-colors, "siwego-darkgreen");
          margin-top: 0;
          font-size: 12px;
          font-weight: 700;
          svg {
            width: 20px;
            margin-right: 10px;
          }
        }

        .badge {
          &.to-do {
            border-radius: 50%;
            background-color: map-get($theme-colors, "danger");
            font-size: 14px;
          }

          &.to-do-green {
            background-color: map-get($theme-colors, "success");
          }
        }
      }

      &:hover {
        background-color: rgba(map-get($theme-colors, "primary"), 0.1);
        cursor: pointer;
      }
    }
  }
}
