@import './../../Variables.scss';

.card{
    &.login-card{
        margin-top: -80px;
        max-width: 600px;
        @media only screen and (max-width: 575px){
            margin-top: -40px;
        }
    }
    .card-header{
        background-color: map-get($theme-colors, "white");
        border: none;
        text-align: center;
        padding-top: 30px;
        .card-title{
            font-size: 20px;
            color: map-get($theme-colors, "darkborder");
            span{
                color: map-get($theme-colors, "black");
                font-weight: 800;
            }
        }
    }
    .card-body{
        padding-right: 40px !important;
        padding-left: 40px !important;
        .btn{
            min-width: 200px;
        }
    }
}