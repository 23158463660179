@import './../../Variables.scss';

.root{
  position: relative;
  height: 75px;
}

.container-processbar{
  width: 100%;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 7px;
  height: 50px;
  .progressbar{
    counter-reset: step;
    padding-left: 0;
    li{
      float: left;
      width: 20%;
      position: relative;
      text-align: center;
      list-style: none;
      font-size: 12px;
      @media only screen and (max-width: 575px){
        font-size: 9px;
      }
      &:hover{
        cursor: pointer;
      }
      &:before{
        content:counter(step);
        counter-increment: step;
        width: 30px;
        height: 30px;
        display: block;
        margin: 0 auto 10px auto;
        border-radius: 50%;
        line-height: 27px;
        color: map-get($theme-colors, "primary-contrast");
        text-align: center;
        font-weight: bold;
        font-size: 16px;
        background-color: #90d2d2;
        border: 2px solid #90d2d2;
        &.active{
          background-color: map-get($theme-colors, "primary-contrast");
          border: 2px solid map-get($theme-colors, "white");
          color: map-get($theme-colors, "white");
        }
      }
      &:after{
        content: '';
        position: absolute;
        width:100%;
        height: 2px;
        background: map-get($theme-colors, "primary-contrast");
        top: 15px;
        left: -50%;
        z-index: -1;
        padding-left: 15px;
      }
      &:first-child{
        &:after{
          content: none;
        }
      }
      &.active:before{
        border-color: map-get($theme-colors, "white") !important;
        color: map-get($theme-colors, "white");
        background-color: map-get($theme-colors, "primary-contrast");
      }
    }
  }
}