@import './../../Variables.scss';

.sct-start{
  height: 40vh;
  .header-logo{
    position: relative;
    height: 20vh;
    border: 4px solid map-get($theme-colors, "background");
    border-top: 0;
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 60px;
    img{
      max-height: 100px;
    }
  }
  @media only screen and (max-width: 575px){
    height: 32vh;
    .header-logo{
      height: 18vh;
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
      img{
        max-height: 70px;
      }
    }
  }
  .position-lang{
    text-align: center;
    margin-top: 20px;
  }
}