@import './../../App.scss';

.header-crm{
  padding: 16px 32px;
  background-color: map-get($theme-colors, "white");
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
  margin-bottom: 24px;
  h2{
    color: map-get($theme-colors, "primary-contrast");
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 0;
    svg{
      width: 30px;
      max-width: 100%;
      height: 100%;
      margin-right: 18px;
    }
  }
  .cont-header-element{
    width: max-content;
    float: right;
    padding-top: 3px;
    p{
      margin-bottom: 0;
      float: right;
      margin-right: 21px;
      span{
        font-weight: 700;
      }
    }
    .btn-logout{
      background-color: map-get($theme-colors, "danger");
      color: map-get($theme-colors, "white");
      float: right;
      margin: 0;
      font-size: 14px;
      padding: 4px 16px !important;
      margin-top: -2px;
      margin-right: 21px;
      font-weight: 700;
      text-transform: uppercase;
    }
    .btn-lang{
      float: right;
    }
  }
  @media only screen and (max-width: 991px){
    h2{
      text-align: center;
      margin-bottom: 8px;
    }
    .cont-header-element{
      float: inherit;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}