@import './../../Variables.scss';

.search-bar{
    input{
        border-color: map-get($theme-colors, "primary");
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        font-weight: 600;
        color: map-get($theme-colors, "primary");
        background-color: transparent;
        &:active,
        &:focus{
            outline: 0;
            box-shadow: none;
            border-color: rgba(map-get($theme-colors, "primary"), 0.6);
        }
    }
    button{
        margin-top: 0;
        font-size: initial;
        background-color: map-get($theme-colors, "primary");
        transition: 0.2s;
        &:hover,
        &:active,
        &:focus{
            outline: 0;
            box-shadow: none;
            opacity: 0.6;
        }
    }
}