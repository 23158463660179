@import "./../../Variables.scss";

.resposive-margin{
  margin-top: 0;
  @media only screen and (max-width: 767px){
    margin-top: 20px;
    margin-bottom: 0;
  }
}
.sales-card{
  .card-header{
    text-align: left;
    color: map-get($theme-colors, "white");
    padding: 20px 16px;
    h3{
      text-align: center;
      font-weight: 700;
      margin-bottom: 10px;
      span{
        font-weight: 300;
      }
      @media only screen and (max-width: 700px){
        font-size: 24px;
      }
    }
    p{
      text-align: center;
      margin-bottom: 8px;
      span{
        font-weight: 700;
        color: map-get($theme-colors, "primary-contrast");
      }
      svg{
        width: 60px;
        margin: 0 15px;
      }
    }
  }
  .card-body{
    padding: 0 !important;
    .alert-body{
      line-height: 35px;
      .alert-icon{
        width: 30px;
        margin-right: 20px;
        display: inline-block;
      }
    }
    .cont-info{
      padding: 20px 60px;
      color: map-get($theme-colors, "primary");
      @media only screen and (max-width: 767px){
        padding: 20px 16px;
      }
      .dotted-list {
        color: inherit;
        list-style-type: none;
        margin: 0;
        padding: 0;
        text-align: left;
        width: 100%;
        .dotted-list-item{
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;
          & + & {
            padding-top: 10px;
          }
        }
        .definition {
          display: block;
          order: 1;
          overflow: hidden;
          position: relative;
          &:after {
            content: "";
            bottom: 3.6px;
            display: none;
            border-bottom: 2px dotted map-get($theme-colors, "border");
            margin-left: 3px;
            position: absolute;
            width: 100%;
          }
        }
        .description {
          float: right;
          order: 2;
          font-weight: 700;
          color: map-get($theme-colors, "primary-contrast");
        }
        @media (min-width: 480px) {
          .dotted-list-item {
            display: block;
          }
          .definition {
            &:after {
              display: inline-block;
            }
          }
          .description {
            padding-left: 3px;
          }
        }
      }
    }
  }
}