@import './../../Variables.scss';

.spinner-container {
  width: 100%;
  margin: auto;
  text-align: center;

  .spinner-border {
    float: inherit;
    color: map-get($theme-colors, "primary");

    @media only screen and (min-width: 992px) {
      float: right;
      margin-right: 20px;
    }
  }
}