@import './../../Variables.scss';

.modal-dialog{
  max-width: 700px;
}
.modal-content{
  border: 0;
  background-color: map-get($theme-colors, "white");
  -webkit-box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.12);
  -moz-box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.12);
  box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.12);
  border-radius: 20px;
  .modal-header{
    background-color: map-get($theme-colors, "primary");
    color: map-get($theme-colors, "white");
    text-align: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    .modal-title{
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      font-weight: 600;
    }
    .close{
      color: map-get($theme-colors, "white");
    }
  }
  .modal-body{
    p{
      color: map-get($theme-colors, "primary");
      text-align: center;
      font-weight: 700;
    }
  }
  .modal-footer{
    border-top: 0;
    .btn{
      margin-top: 0;
      font-size: 15px;
    }
    .btn-secondary{
      background-color: map-get($theme-colors, "danger");
    }
  }
}