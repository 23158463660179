// VARIABLES

$theme-colors: (
  "background": #F8F6F6,
  "black": #374140,
  "white": #ffffff,
  "grey": #DDDDDD,
  "pink": #E0C7C2,
  "primary": #51A3A3,
  "primary-contrast": #2F5D5D,
  "siwego-green": #BEDB39,
  "siwego-darkgreen": #74842C,
  "siwego-sand": #D9CB9E,
  "border": #C9C9C9,
  "darkborder": #777777,
  "success": #8FC485,
  "danger": #D87373,
  "warning": #ED9B00, 
  "appointment_scheduled": #FFB238,
  "qualified_to_buy": #FF6B38,
  "presentation_scheduled": #C75146,
  "contract_sent": #AD2E24,
  "closed_won": #81171B,
  "closed_lost": #540804,
  "freezed": #87A6FA,
);

$OpenSans: 'Open Sans', sans-serif;

$menu-size: 130px;
