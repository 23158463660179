@import "./../../App.scss";

.nav-tabs {
  border-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
  .nav-item {
    margin-bottom: 0;
    margin-right: 20px;
    .nav-link {
      border: 0;
      border-bottom: 3px solid transparent;
      font-weight: 700;
      opacity: 0.7;
      color: map-get($theme-colors, "black");
      transition: 0.2s ease-in-out;
      &.active {
        background-color: transparent;
        border-radius: 0;
        border-bottom-color: map-get($theme-colors, "primary-contrast");
        opacity: 1;
      }
    }
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      cursor: pointer;
      .nav-link {
        opacity: 1;
      }
    }
  }
}

.profile-form {
  .edit-element {
    margin-top: 0;
    height: fit-content;
  }
}
